<template>
	<v-app id="main-app">
	<v-container class="fill-height" fluid>
		<v-card class="mx-auto px-10 pb-9 login-card" width="450px">
			<v-card-title class="justify-center pt-12"><img v-bind:src="logo.src" v-bind:style="{ width: logo.width, height: logo.height }"></v-card-title>
			<v-card-subtitle class="text-center py-6 title-login">Welcome to Vital Checkups</v-card-subtitle>
			<v-card-text class="text-center">
				<v-form ref="form1" v-if="showForm === 1" @submit.prevent="login">
					<v-text-field
                        v-model="email"
						ref="email"
						label="Email"
						name="email"
						type="email"
                        prepend-inner-icon="mdi-email mr-4 mb-1"
                        background-color="#E8E8E8"
                        rounded
                        outlined
                        style="border-radius: 12px !important; font-family: 'Catamaran', serif !important;"
                        :rules="[rules.emailRequired, rules.email]"
                        @keyup.enter="clickButton"
                    />
					<v-text-field
                        v-model="password"
						label="Password"
                        prepend-inner-icon="mdi-lock mr-4 mb-1"
                        background-color="#E8E8E8"
                        rounded
                        outlined
                        style="border-radius: 12px !important; font-family: 'Catamaran', serif !important;"
                        :rules="[rules.passwordRequired]"
						:append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
						:autocomplete="showPassword ? 'off' : 'current-password'"
						:type="showPassword ? 'text' : 'password'"
                        @click:append="showPassword = !showPassword"
                        @keyup.enter="clickButton"
                    />
				</v-form>
				<v-form ref="form2" v-if="showForm === 2" @submit.prevent="resetPassword">
                    <v-input v-if="!emailSent" class="primary--text receive-email-text">Enter your email address below to receive a password reset email.</v-input>
                    <v-input v-else class="primary--text receive-email-text">We sent a password reset link to <b class="ml-2">{{ this.email }}</b></v-input>
					<v-text-field
                        v-if="!emailSent"
                        v-model="email"
						label="Email"
						name="email"
						type="email"
                        prepend-inner-icon="mdi-email-send mr-4 mb-1"
                        background-color="#E8E8E8"
                        rounded
                        outlined
                        style="border-radius: 12px !important; font-family: 'Catamaran', serif !important;"
                        :rules="[rules.emailRequired, rules.email]" />
				</v-form>
			</v-card-text>
			<v-card-actions>
				<v-btn text v-if="showForm !== 2" @click="showForm = 2" class="ma-2 title-forgot-password">Forgot Password</v-btn>
				<v-btn text v-if="showForm !== 1 && !emailSent" @click="showForm = 1" class="ma-2 title-forgot-password">Sign In</v-btn>
                <v-btn text v-if="showForm === 2 && emailSent" @click="showform" class="ma-2 title-forgot-password">
                    <v-icon class="primary--text mr-3">mdi-arrow-left-bottom</v-icon>
                    <span>Back to sign in</span>
                </v-btn>
				<v-spacer />
				<v-btn color="primary" v-if="!emailSent" large class="ma-3 button-login" @click="clickButton">{{ action }}</v-btn>
			</v-card-actions>
		</v-card>
		<v-footer color="transparent" absolute padless>
			<v-col class="text-center black--text" cols="12">
				&#183; <img :src="logo_bridge" height="24px"> &#183;
			</v-col>
		</v-footer>
	</v-container>
    <Alert
      :title="snackTitle"
      :status="snackStatus"
      :color="snackColor"
    ></Alert>
	</v-app>
</template>

<script>
import logo_bridge from '../../../public/logo_bridge.png'
import Alert from "@/views/Client/components/Alert";
import Login from "../../data/models/Login";

const { LOGIN_LOGO } = require(`@/themes/VitalCheckups/image-paths`)

export default {
    components: {
        Alert,
    },
    data () {
        return {
            logo: LOGIN_LOGO,
            showForm: 1,
            showPassword: false,
            logo_bridge:logo_bridge,
            rules: {
				emailRequired: value => !!value || 'Enter your email address',
				email: value => {
					const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
					return pattern.test(value) || 'Enter a valid e-mail address';
				},
				passwordRequired: value => !!value || 'Enter your password',
			},
            email: '',
			password: '',
            emailSent: false,
            snackStatus: false,
            snackTitle: "",
            snackColor: "",
        }
    },
    computed: {
        action () {
            switch (this.showForm) {
			case 1: return 'Sign In'
			case 2: return 'Submit'
            default: return ''
			}
        }
    },
    mounted () {
        if (this.$store.getters["authentication/hasStoredSession"]) {
            this.$store.dispatch("authentication/doLogout");
            localStorage.clear();
        }
    },
    methods: {
        showform () {
            this.showForm = 1
            this.emailSent = false
        },
        async login() {
            const login = new Login({
                username: this.email,
                password: this.password,
            });
            this.snackStatus = false;
            await this.$store.dispatch("authentication/doLogin", login)
                .then(() => {
                    switch (this.$store.getters['authentication/getRole']) {
                        case 'SYSTEM_ADMIN':
                            this.$router.replace(this.$route.query.redirect || "/medicalorganizations");
                            break;
                        case 'HOSPITAL_ADMIN':
                            this.$router.replace(this.$route.query.redirect || "/medicalstaff");
                            break;
                        default:
                            this.$router.replace(this.$route.query.redirect || "/");
                            break;
                    }
                })
                .catch((err) => {
                    this.snackStatus = true;
                    this.snackTitle = err.response === undefined ? "This role is not allowed to use the web application!" : err.response.data.msg;
                    this.snackColor = "main_red darken-4";
                });
            await this.$store.dispatch("setupWebSockets");
        },
        async resetPassword() {
            const body = {
                email: this.email,
            };
            await this.$store.dispatch("users/sendValidationLinkByEmail", body)
                .then((res) => {
                    if (res.resFlag) {
                        this.emailSent = true
                    }
                })
                .catch(() => {
                    this.alertSuccess = false;
                    this.alertError = true;
                });
        },
        clickButton () {
			switch (this.showForm) {
			case 1: return this.login();
			case 2: return this.resetPassword();
			}
		},
    }
}
</script>
<style scoped>
    #main-app {
        background: linear-gradient(#E8E8E8, #d6d6d6);
    }
</style>